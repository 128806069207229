import { zodResolver } from "@hookform/resolvers/zod";
import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { t } from "i18next";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import moment from "moment";
import formUiConfig from "./formUiConfig";
import { useParams } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";
import { PatientSettingsType } from "@/types";
import { patientSettingsDefault, PatientSettingsFormSchema } from "@/config";
import { apiGetPatientSettings, apiUpdatePatientSettings } from "@/api";
import { Loader2 } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";

const PatientSettings = () => {
  const [submitFetching, setSubmitFetching] = useState<boolean>(false);
  const [activeSettings, setActiveSettings] =
    useState<PatientSettingsType | null>(null);
  const { patientId } = useParams();
  const { toast } = useToast();

  useEffect(() => {
    (async () => {
      try {
        if (!patientId) return;

        setSubmitFetching(true);

        const activeSettings = await apiGetPatientSettings(patientId);
        setActiveSettings(activeSettings);

        if (activeSettings) {
          form.reset(activeSettings);
        }
      } catch (err) {
        console.error(err);
        toast({
          title: "Error getting patient settings", // !TODO
          variant: "destructive",
        });
      } finally {
        setSubmitFetching(false);
      }
    })();
    // eslint-disable-next-line
  }, [patientId]);

  const form = useForm<z.infer<typeof PatientSettingsFormSchema>>({
    resolver: zodResolver(PatientSettingsFormSchema),
    defaultValues: activeSettings ?? patientSettingsDefault,
  });

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        if (!patientId) return;
        setSubmitFetching(true);

        const result = await apiUpdatePatientSettings(
          patientId,
          form.getValues()
        );

        if (!result) {
          throw new Error("Error update patient settings");
        }

        setActiveSettings(form.getValues());
        toast({ title: "Patient settings updated" });
      } catch (err) {
        form.reset();
        console.error(err);
        toast({
          title: "Error updating patient settings",
          variant: "destructive",
        });
      } finally {
        setSubmitFetching(false);
      }
    },
    // eslint-disable-next-line
    [patientId, activeSettings]
  );

  return (
    <ScrollArea className="p-2 h-full items-center justify-center">
      <Form {...form}>
        <form className="px-[25%]">
          <div className="mb-2">
            <h2 className="text-[#11203D] font-semibold text-lg mb-[24px]">
              {formUiConfig.featuresSettingsSectionLabel}
            </h2>
            {/* === call_back feature === */}
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="call_back"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-row w-full items-start">
                    <div className="flex flex-col w-full mr-16">
                      <FormLabel
                        htmlFor="id_call_back"
                        className="mb-2 cursor-pointer"
                      >
                        {formUiConfig.featureCallBackLabel}
                      </FormLabel>
                      <FormDescription>
                        {formUiConfig.featureCallbackDescription}
                      </FormDescription>
                    </div>
                    <FormControl id="id_call_back">
                      <Switch
                        disabled={submitFetching}
                        className="focus-visible:ring-0"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </div>
                  {/* !FIXME */}
                  <div className="w-full h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            {/* === view_messages === */}
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="view_messages"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-row w-full items-start">
                    <div className="flex flex-col w-full mr-16">
                      <FormLabel
                        htmlFor="id_view_messages"
                        className="mb-2 cursor-pointer"
                      >
                        {formUiConfig.featureViewMessagesLabel}
                      </FormLabel>
                      <FormDescription>
                        {formUiConfig.featureViewMessageDescription}
                      </FormDescription>
                    </div>
                    <FormControl id="id_view_messages">
                      <Switch
                        disabled={submitFetching}
                        className="focus-visible:ring-0"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </div>
                  <div className="w-full h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            {/* === reply_messages === */}
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="reply_messages"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-row w-full items-start">
                    <div className="flex flex-col w-full mr-16">
                      <FormLabel
                        htmlFor="id_reply_messages"
                        className="mb-2 cursor-pointer"
                      >
                        {formUiConfig.featureReplyMessagesLabel}
                      </FormLabel>
                      <FormDescription>
                        {formUiConfig.featureReplyMessagesDescription}
                      </FormDescription>
                    </div>
                    <FormControl id="id_reply_messages">
                      <Switch
                        disabled={submitFetching}
                        className="focus-visible:ring-0"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </div>
                  <div className="w-full h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            {/* === autoplay_video === */}
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="autoplay_video"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-row w-full items-start">
                    <div className="flex flex-col w-full mr-16">
                      <FormLabel
                        htmlFor="id_autoplay_video"
                        className="mb-2 cursor-pointer"
                      >
                        {formUiConfig.featureAutoplayVideoLabel}
                      </FormLabel>
                      <FormDescription>
                        {formUiConfig.featureAutoplayVideoDescription}
                      </FormDescription>
                    </div>
                    <FormControl id="id_autoplay_video">
                      <Switch
                        disabled={submitFetching}
                        className="focus-visible:ring-0"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </div>
                  <div className="w-full h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
          </div>

          {/* === CALENDAR SETTINGS === */}
          <div className="mb-2">
            <h2 className="text-[#11203D] font-semibold text-lg mb-[24px]">
              {formUiConfig.calendarSettingsSectionLabel}
            </h2>
            {/* === display_start_time === */}
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="display_start_time"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-row w-full items-start">
                    <div className="flex flex-col w-full mr-16">
                      <FormLabel
                        htmlFor="id_display_start_time"
                        className="mb-2 cursor-pointer"
                      >
                        {formUiConfig.featureDislayStartTimeLabel}
                      </FormLabel>
                      <FormDescription>
                        {formUiConfig.featureDisplayStartTimeDescription}
                      </FormDescription>
                    </div>
                    <FormControl id="id_display_start_time">
                      <Switch
                        disabled={submitFetching}
                        className="focus-visible:ring-0"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </div>
                  <div className="w-full h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            {/* === display_finish_time === */}
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="display_finish_time"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-row w-full items-start">
                    <div className="flex flex-col w-full mr-16">
                      <FormLabel
                        htmlFor="id_display_finish_time"
                        className="mb-2 cursor-pointer"
                      >
                        {formUiConfig.featureDislayFinishTimeLabel}
                      </FormLabel>
                      <FormDescription>
                        {formUiConfig.featureDisplayFinishTimeDescription}
                      </FormDescription>
                    </div>
                    <FormControl id="id_display_finish_time">
                      <Switch
                        disabled={submitFetching}
                        className="focus-visible:ring-0"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </div>
                  <div className="w-full h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            {/* === manage_calendar === */}
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="manage_calendar"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-row w-full items-start">
                    <div className="flex flex-col w-full mr-16">
                      <FormLabel
                        htmlFor="id_manage_calendar"
                        className="mb-2 cursor-pointer"
                      >
                        {formUiConfig.featureManageCalendarLabel}
                      </FormLabel>
                      <FormDescription>
                        {formUiConfig.featureManageCalendarDescription}
                      </FormDescription>
                    </div>
                    <FormControl id="id_manage_calendar">
                      <Switch
                        disabled={submitFetching}
                        className="focus-visible:ring-0"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </div>
                  <div className="w-full h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            {/* === main_screen_type === */}
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="main_screen_type"
              render={({ field }) => (
                <FormItem className="flex flex-col w-full mb-4">
                  <div className="mr-4 mb-2">
                    <FormLabel
                      className="font-semibold text-sm text-[#11203D]"
                      htmlFor="id_main_screen_type"
                    >
                      {formUiConfig.featureMainScreenTypeLabel}
                    </FormLabel>
                    <FormDescription>
                      {formUiConfig.featureMainScreenTypeDescription}
                    </FormDescription>
                  </div>
                  <FormControl id="id_main_screen_type">
                    <RadioGroup
                      disabled={submitFetching}
                      id="id_main_screen_type_group"
                      className="border-0 gap-1"
                      value={field.value}
                      defaultValue={field.value}
                      onValueChange={(value) => {
                        field.onChange(value);
                      }}
                    >
                      <div className="flex items-center space-x-2 border-0 my-0">
                        <RadioGroupItem
                          className="text-[#1949a3]"
                          value="default"
                          id="id_default"
                        />
                        <Label
                          className="text-sm font-normal text-[#11203D]"
                          htmlFor="id_default"
                        >
                          {formUiConfig.featureMainScreenTypeValueDefault}
                        </Label>
                      </div>

                      <div className="flex items-center space-x-2 border-0 my-0">
                        <RadioGroupItem
                          className="text-[#1949a3]"
                          value="contrast"
                          id="id_contrast"
                        />
                        <Label
                          className="text-sm font-normal text-[#11203D]"
                          htmlFor="id_contrast"
                        >
                          {formUiConfig.featureMainScreenTypeValueContrast}
                        </Label>
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <div className="w-full h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />

            {/* === display_activities_mode === */}
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="display_activities_mode"
              render={({ field }) => (
                <FormItem className="flex flex-col w-full mb-4">
                  <div className="mr-4 mb-2">
                    <FormLabel
                      className="font-semibold text-sm text-[#11203D]"
                      htmlFor="display_activities_mode"
                    >
                      {formUiConfig.featureDisplayActivitiesModeLabel}
                    </FormLabel>
                    <FormDescription>
                      {formUiConfig.featureDisplayActivitiesModeDescription}
                    </FormDescription>
                  </div>
                  <FormControl id="display_activities_mode">
                    <RadioGroup
                      disabled={submitFetching}
                      id="idRepeat"
                      className="border-0 gap-1"
                      value={field.value}
                      defaultValue={field.value}
                      onValueChange={(value) => {
                        field.onChange(value);
                      }}
                    >
                      <div
                        // key={repeatTypeOption.value}
                        className="flex items-center space-x-2 border-0 my-0"
                      >
                        <RadioGroupItem
                          className="text-[#1949a3]"
                          value="full_schedule"
                          id="id_full_schedule"
                        />
                        <Label
                          className="text-sm font-normal text-[#11203D]"
                          htmlFor="id_full_schedule"
                        >
                          {formUiConfig.featureDisplayActivitiesModeValueFull}
                        </Label>
                      </div>

                      <div
                        // key={repeatTypeOption.value}
                        className="flex items-center space-x-2 border-0 my-0"
                      >
                        <RadioGroupItem
                          className="text-[#1949a3]"
                          value="last_3_activities"
                          id="id_last_3_activities"
                        />
                        <Label
                          className="text-sm font-normal text-[#11203D]"
                          htmlFor="id_last_3_activities"
                        >
                          {formUiConfig.featureDisplayActivitiesModeValueLast3}
                        </Label>
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <div className="w-full h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
          </div>

          {/* === CUSTOM SETTINGS === */}
          <div className="mb-2">
            <h2 className="text-[#11203D] font-semibold text-lg mb-[24px]">
              {formUiConfig.customSettingsSectionLabel}
            </h2>
            {/* === night time period === */}
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="night_time_range"
              render={({ field }) => (
                <FormItem className="flex flex-col items-start w-full justify-between mb-4">
                  <div className="mr-4 mb-2">
                    <FormLabel
                      htmlFor="id_night_time_range"
                      className="font-semibold text-sm text-[#11203D]"
                    >
                      {formUiConfig.featureNightTimePeriodLabel}
                    </FormLabel>
                    <FormDescription className="font-normal text-sm color-[#858992]">
                      {formUiConfig.featureNightTimePeriodDescription}
                    </FormDescription>
                  </div>
                  <FormControl id="id_night_time_range">
                    <div className="flex flex-1 w-full">
                      <input
                        disabled={submitFetching}
                        onChange={(e) => {
                          const [hour, minute] = e.target.value.split(":");
                          field.onChange({
                            ...field.value,
                            from: {
                              hour: parseInt(hour, 10),
                              minute: parseInt(minute, 10),
                            },
                          });
                        }}
                        value={moment()
                          .set({
                            hour: field.value.from.hour,
                            minute: field.value.from.minute,
                          })
                          .format("HH:mm")}
                        onBlur={field.onBlur}
                        type="time"
                        className="w-1/2 border-[1px] px-2 rounded-md mr-2 font-normal text-sm text-[#858992]"
                      />
                      <input
                        disabled={submitFetching}
                        onChange={(e) => {
                          const [hour, minute] = e.target.value.split(":");
                          field.onChange({
                            ...field.value,
                            to: {
                              hour: parseInt(hour, 10),
                              minute: parseInt(minute, 10),
                            },
                          });
                        }}
                        value={moment()
                          .set({
                            hour: field.value.to.hour,
                            minute: field.value.to.minute,
                          })
                          .format("HH:mm")}
                        onBlur={field.onBlur}
                        type="time"
                        className="w-1/2 border-[1px] px-2 rounded-md ml-2 font-normal text-sm text-[#858992]"
                      />
                    </div>
                  </FormControl>
                  <div className="w-full h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div className="flex justify-end">
            <Button
              disabled={submitFetching}
              className="px-[18px] focus-visible:ring-0"
              size="default"
              type="button"
              onClick={form.handleSubmit(onSubmit)}
            >
              {t("common.save")}
            </Button>
          </div>
        </form>
      </Form>
      {submitFetching && (
        <Loader2 className="h-16 w-16 text-primary/60 animate-spin absolute z-10 top-1/2 left-1/2" />
      )}
    </ScrollArea>
  );
};

export default memo(PatientSettings);
