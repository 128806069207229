import { FC, memo, ReactElement } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon/icon";
import MediaToolTip from "./MediaToolTip";
import { MediaCategoryDataType } from "@/types";

type MediaCategoryEntityPropsType = {
  entityData: MediaCategoryDataType;
  actionsMenuEnabled: boolean;
  ActionsDropdown: ReactElement<any>;
  onClick: (entityData: MediaCategoryDataType) => void;
};

const MediaCategoryEntity: FC<MediaCategoryEntityPropsType> = ({
  entityData,
  actionsMenuEnabled,
  ActionsDropdown,
  onClick,
}) => {
  return (
    <Card className="h-44 w-44 overflow-hidden flex-col flex">
      <CardHeader className="flex-row items-center justify-between p-0 py-3 h-14 min-h-14 max-h-14 overflow-hidden">
        <CardTitle className="ml-2 font-medium text-sm line-clamp-2 break-words text-[#11203D]">
          {entityData.name}
        </CardTitle>
        {actionsMenuEnabled ? ActionsDropdown : <MediaToolTip />}
      </CardHeader>
      <CardContent className="overflow-hidden items-center justify-center flex p-0 pb-2 px-6 w-full h-full relative">
        <div
          onClick={() => onClick(entityData)}
          className="cursor-pointer flex w-full h-full rounded-md justify-center items-center bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${
              entityData.is_package
                ? "/icons/innocom-folder.png"
                : "/icons/folder.png"
            })`,
          }}
        >
          {(entityData?.icon?.length && !entityData.is_package) ? (
            <Icon
              pack="innocom"
              color="#3A70D4"
              image={`icon-${entityData?.icon}`}
              className="!text-6xl"
            />
          ) : null}
        </div>
      </CardContent>
    </Card>
  );

  // return (
  //   <Card className="h-[180px] w-[180px] overflow-hidden flex-col flex">
  //     <CardHeader className="flex flex-row p-0 items-center justify-between py-[12px] min-h-[50px]">
  //       <CardTitle className="ml-[8px] text-sm font-medium line-clamp-2 text-ellipsis overflow-hidden break-words text-[#11203D]">
  //         {entityData.name}
  //       </CardTitle>
  //       {actionsMenuEnabled ? ActionsDropdown : <MediaToolTip />}
  //     </CardHeader>
  //     <CardContent
  //       className="cursor-pointer border-1 w-full h-full border-blue-200 flex grow-1 p-0 px-[12px] pb-[12px]"
  //       onClick={() => onClick(entityData)}
  //     >
  //       <div className="w-full h-full border-grey-50 flex justify-center items-center rounded-md">
  //         <div
  //           className="flex h-40 w-40 -mt-5 bg-cover bg-center bg-no-repeat justify-center items-center"
  //           style={{
  //             backgroundImage: `url(${
  //               entityData.is_package
  //                 ? "/icons/innocom-folder.png"
  //                 : "/icons/folder.png"
  //             })`,
  //           }}
  //         >
  //           {entityData?.icon?.length && !entityData.is_package ? (
  //             <Icon
  //               pack="innocom"
  //               color="#3A70D4"
  //               image={`icon-${entityData?.icon}`}
  //               className="mt-[5px] !text-[60px]"
  //             />
  //           ) : null}
  //         </div>
  //       </div>
  //     </CardContent>
  //   </Card>
  // );
};

export default memo(MediaCategoryEntity);
