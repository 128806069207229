import { FC, memo, useState } from "react";
import { t } from "i18next";
import { MoreVertical, Trash2 } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import AddContentDialog from "../../ui/dialog/AddContentDialog";
import { MediaContentDataType } from "@/types";
import AddUpdatePlaylistDialog from "../../ui/dialog/AddUpdatePlaylistDialog";
import AddUpdateExtSourceDialog from "../../ui/dialog/AddUpdateExtSourceDialog";
import AddUpdateGameDialog from "@/components/ui/dialog/AddUpdateGameDialog";

type MediaContentActionsDropdownPropType = {
  onMenuDelete: () => void;
  canEdit: boolean;
  canDelete: boolean;
  parentCategoryId?: string,
  selectedContent?: MediaContentDataType,
  apiPrefix: string;
  onUpdateContent: (errMsg: string | null, successMsg?: string) => void,
};

const MediaContentActionsDropdown: FC<MediaContentActionsDropdownPropType> = ({
  onMenuDelete,
  canEdit,
  canDelete,
  parentCategoryId,
  selectedContent,
  apiPrefix,
  onUpdateContent,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dialogCommonProps = {
    disabled: !canEdit,
    onOpenClose: setDropdownOpen,
    apiPrefix,
    parentCategoryId,
    selectedContent,
    onSubmit: onUpdateContent,
  };

  return (
    <DropdownMenu
      modal={false}
      open={dropdownOpen}
      onOpenChange={setDropdownOpen}
    >
      <DropdownMenuTrigger
        className="px-[12px] items-center justify-center relative"
        onClick={(e) =>  {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <MoreVertical
          className="h-3.5 w-3.5 !m-0"
          color={"#11203D"}
        />
      </DropdownMenuTrigger>
    
      <DropdownMenuContent>
        {["video", "document"].includes(selectedContent?.media_type as string) && (
          <AddContentDialog {...dialogCommonProps} />
        )}
        {selectedContent?.media_type === "playlist" && (
          <AddUpdatePlaylistDialog {...dialogCommonProps} />
        )}
        {selectedContent?.media_type === "external_source" && (
          <AddUpdateExtSourceDialog {...dialogCommonProps} />
        )}
        {selectedContent?.media_type === "game" && (
          <AddUpdateGameDialog { ...dialogCommonProps } />
        )}
        <DropdownMenuItem
          onClick={(e) => {
            e.stopPropagation();
            onMenuDelete();
          }}
          disabled={!canDelete}
        >
          <Trash2 className="h-3.5 w-3.5 mr-2" color="#11203D" />
          {t("mediaLibraryScreen.entity.delete")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default memo(MediaContentActionsDropdown);
