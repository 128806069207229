import { FC, memo, ReactElement } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { getEntityDefaultIcon } from "../MediaEntity/helpers";
import MediaToolTip from "./MediaToolTip";
import { MediaContentDataType } from "@/types";
import { Import } from "lucide-react";
import { Icon } from "../../icon/icon";

type MediaContentEntityPropsType = {
  entityData: MediaContentDataType; // !TODO: common
  isPackage: boolean;
  actionsMenuEnabled: boolean;
  onClick: (entityData: MediaContentDataType) => void;
  ActionsDropdown: ReactElement<any>;
};

const MediaContentEntity: FC<MediaContentEntityPropsType> = ({
  entityData,
  isPackage,
  actionsMenuEnabled,
  onClick,
  ActionsDropdown,
}) => {
  const renderMediaCategoryInner = (isShared = false) => {
    return (
      <div className="overflow-hidden items-center justify-center flex p-0 pb-2 px-6 w-full h-full relative">
        <div
          onClick={() => onClick(entityData)}
          className="cursor-pointer flex w-full h-full rounded-md justify-center items-center bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${
              entityData.is_package
                ? "/icons/innocom-folder.png"
                : "/icons/folder.png"
            })`,
          }}
        >
          {(entityData?.icon?.length && !entityData.is_package) ? (
            <Icon
              pack="innocom"
              color="#3A70D4"
              image={`icon-${entityData?.icon}`}
              className="!text-6xl"
            />
          ) : null}
        </div>
        {isShared && (
          <Import
            color="#858992"
            strokeWidth={1}
            className="absolute right-2 bottom-1 w-6 h-6"
          />
        )}
      </div>
    );
  };

  const renderMediaContentInner = (isShared = false) => {
    return (
      <div className="overflow-hidden items-center justify-center flex p-0 pb-2 px-2 w-full h-full relative">
        <div
          onClick={() => onClick(entityData)}
          style={{ backgroundImage: `url(${entityData.blob_preview_url})` }}
          className="cursor-pointer w-full h-full bg-grey-background border-[1px] border-grey-50 flex justify-center items-center rounded-md relative bg-cover bg-center bg-no-repeat"
        >
          {(entityData.blob_preview_url && entityData.media_type === "video") && (
            <div className="w-full h-full flex items-center justify-center bg-[#00000033]">
              <div
                className="w-8 h-8 p-0 bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url("/icons/play.png")` }}
              />
            </div>
          )}
          {!entityData.blob_preview_url && (
            <div
              className="w-20 h-20 p-0 bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${getEntityDefaultIcon(
                  isPackage,
                  entityData
                )})`,
              }}
            />
          )}
        </div>
        {isShared && (
          <Import
            color="#858992"
            strokeWidth={1}
            className="absolute right-4 bottom-3 w-6 h-6"
          />
        )}
      </div>
    );
  };

  return (
    <Card className="h-44 w-44 overflow-hidden flex-col flex">
      <CardHeader className="flex-row items-center justify-between p-0 py-3 h-14 min-h-14 max-h-14 overflow-hidden">
        <CardTitle className="ml-2 font-medium text-sm line-clamp-2 break-words text-[#11203D]">
          {entityData.name}
        </CardTitle>
        {actionsMenuEnabled ? ActionsDropdown : <MediaToolTip />}
      </CardHeader>
      <CardContent className="overflow-hidden w-full h-full p-0">
        {["shared_category"].includes(entityData?.media_type as string)
          ? renderMediaCategoryInner(true)
          : renderMediaContentInner("shared_resource" === entityData?.media_type)
        }
      </CardContent>
    </Card>
  );

  // return (
  //   <Card className="h-[180px] w-[180px] overflow-hidden flex-col flex">
  //     <CardHeader className="flex-row items-center justify-between px-0 py-[12px] min-h-[50px]">
  //       <CardTitle className="ml-[8px] text-sm font-medium text-[#11203D] line-clamp-2 text-ellipsis overflow-hidden break-words mr-[4px]">
  //         {entityData.name} 
  //       </CardTitle>
  //       {actionsMenuEnabled ? ActionsDropdown : <MediaToolTip />}
  //     </CardHeader>
  //     <CardContent
  //       onClick={() => onClick(entityData)}
  //       className="cursor-pointer border-1 w-full h-full border-blue-200 flex grow-1 p-0 px-[12px] pb-[12px] relative"
  //     >
  //       <div
  //         className={
  //           entityData.media_type === "shared_category"
  //             ? "w-full h-full flex justify-center items-center rounded-md bg-cover bg-center bg-no-repeat overflow-hidden"
  //             : "border-[1px] w-full h-full border-grey-50 flex justify-center items-center bg-grey-background rounded-md bg-cover bg-center bg-no-repeat"
  //         }
  //         style={{ backgroundImage: `url(${entityData.blob_preview_url})` }}
  //       >
  //         {entityData.media_type === "shared_category" && (
  //           <div
  //             className="flex h-40 w-40 -mt-5 bg-cover bg-center bg-no-repeat justify-center items-center"
  //             style={{
  //               backgroundImage: `url(${getEntityDefaultIcon(
  //                 isPackage,
  //                 entityData
  //               )})`,
  //             }}
  //           >
  //             {entityData?.icon?.length &&
  //             !entityData.is_package &&
  //             !entityData?.original_media_type ? (
  //               <Icon
  //                 pack="innocom"
  //                 color="#3A70D4"
  //                 image={`icon-${entityData?.icon}`}
  //                 className="mt-[5px] !text-[60px]"
  //               />
  //             ) : null}
  //           </div>
  //         )}

  //         {["shared_category", "shared_resource"].includes(
  //           entityData?.media_type as string
  //         ) && (
  //           <Import
  //             color="#858992"
  //             className={`absolute ${
  //               "shared_resource" === entityData?.media_type
  //                 ? "bottom-4 right-6"
  //                 : "bottom-10 right-8"
  //             } `}
  //           />
  //         )}
  //         {!entityData.blob_preview_url &&
  //           entityData.media_type !== "shared_category" && (
  //             <div
  //               className="w-[80px] h-[80px] p-0 bg-cover bg-center bg-no-repeat"
  //               style={{
  //                 backgroundImage: `url(${getEntityDefaultIcon(
  //                   isPackage,
  //                   entityData
  //                 )})`,
  //               }}
  //             />
  //         )}
  //         {entityData.blob_preview_url && entityData.media_type === "video" && (
  //           <div className="w-full h-full flex items-center justify-center bg-[#00000033]">
  //             <div
  //               className="w-[32px] h-[32px] p-0 bg-cover bg-center bg-no-repeat"
  //               style={{ backgroundImage: `url("/icons/play.png")` }}
  //             />
  //           </div>
  //         )}
  //       </div>
  //     </CardContent>
  //   </Card>
  // );
};

export default memo(MediaContentEntity);
