import { FC, memo, MouseEventHandler } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { MediaContentDataType } from "@/types";
import { getEntityDefaultIcon } from "../../mediaEditor/MediaEntity/helpers";

import { Checkbox } from "../../checkbox";
import { Import } from "lucide-react";
import { Icon } from "../../icon/icon";

type PlaylistContentEntityPropsType = {
  entityData: MediaContentDataType; // !TODO: common
  isSelected?: boolean;
  isPackage: boolean;
  actionsMenuEnabled: boolean;
  onClick: (entityData: MediaContentDataType) => void;
  onCheck: (entityData: MediaContentDataType, checked: boolean) => void;
};

const PlaylistContentEntity: FC<PlaylistContentEntityPropsType> = ({
  entityData,
  isSelected,
  isPackage,
  onClick,
  onCheck,
}) => {
  const entityContentData = entityData?.linked_resource ?? entityData;

  const onCheckClick: MouseEventHandler<HTMLButtonElement> = (e: any) => {
    const {
      dataset: { state },
    } = e.target;
    onCheck(entityData, state !== "checked");
  };

  const renderMediaCategoryInner = (isShared = false) => {
    return (
      <div className="overflow-hidden items-center justify-center flex p-0 pb-2 px-6 w-full h-full relative">
        <div
          onClick={() => onClick(entityContentData)}
          className="cursor-pointer flex w-full h-full rounded-md justify-center items-center bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${
              entityContentData.is_package
                ? "/icons/innocom-folder.png"
                : "/icons/folder.png"
            })`,
          }}
        >
          {(entityContentData?.icon?.length && !entityContentData.is_package) ? (
            <Icon
              pack="innocom"
              color="#3A70D4"
              image={`icon-${entityContentData?.icon}`}
              className="!text-6xl"
            />
          ) : null}
        </div>
        {isShared && (
          <Import
            color="#858992"
            strokeWidth={1}
            className="absolute right-2 bottom-1 w-6 h-6"
          />
        )}
      </div>
    );
  };

  const renderMediaContentInner = (isShared = false) => {
    return (
      <div className="overflow-hidden items-center justify-center flex p-0 pb-2 px-2 w-full h-full relative">
        <div
          onClick={() => onClick(entityData)}
          style={{ backgroundImage: `url(${entityContentData.blob_preview_url})` }}
          className="cursor-pointer w-full h-full bg-grey-background border-[1px] border-grey-50 flex justify-center items-center rounded-md relative bg-cover bg-center bg-no-repeat"
        >
          {(entityContentData.blob_preview_url && entityContentData.media_type === "video") && (
            <div className="w-full h-full flex items-center justify-center bg-[#00000033]">
              <div
                className="w-8 h-8 p-0 bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url("/icons/play.png")` }}
              />
            </div>
          )}
          {!entityContentData.blob_preview_url && (
            <div
              className="w-20 h-20 p-0 bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${getEntityDefaultIcon(
                  isPackage,
                  entityContentData
                )})`,
              }}
            />
          )}
        </div>
        {isShared && (
          <Import
            color="#858992"
            strokeWidth={1}
            className="absolute right-4 bottom-3 w-6 h-6"
          />
        )}
      </div>
    );
  };

  return (
    <Card className="h-44 w-44 overflow-hidden flex-col flex">
      <CardHeader className="flex-row items-center justify-between p-0 py-3 h-14 min-h-14 max-h-14 overflow-hidden">
        <CardTitle className="ml-2 font-medium text-sm line-clamp-2 break-words text-[#11203D]">
          {entityContentData?.name}
        </CardTitle>
        <Checkbox
          aria-label="select item"
          checked={isSelected}
          onClick={onCheckClick}
          id={entityData?.id}
          className="focus-visible:ring-0 border-[#E0E0E0] data-[state=checked]:bg-[#1949A3] data-[state=checked]:border-[#E0E0E0] mr-2"
        />
      </CardHeader>
      <CardContent className="overflow-hidden w-full h-full p-0">
        {["shared_category"].includes(entityData?.media_type as string)
          ? renderMediaCategoryInner(true)
          : renderMediaContentInner("shared_resource" === entityData?.media_type)
        }
      </CardContent>
    </Card>
  );

//   return (
//     <Card className="h-[180px] w-[180px] overflow-hidden flex-col flex">
//       <CardHeader className="p-0 pe-[12px] ps-[8px] py-[8px] flex flex-row items-center justify-between min-h-[50px]">
//         <CardTitle className="text-sm text-[#11203D] font-medium overflow-hidden line-clamp-2 text-ellipsis break-words mr-[4px]">
//           {entityContentData?.name}
//         </CardTitle>
//         <Checkbox
//           aria-label="select item"
//           checked={isSelected}
//           onClick={onCheckClick}
//           id={entityData?.id}
//           className="focus-visible:ring-0 border-[#E0E0E0] data-[state=checked]:bg-[#1949A3] data-[state=checked]:border-[#E0E0E0]"
//         />
//       </CardHeader>
//       <CardContent
//         onClick={() => onClick(entityData)}
//         className="cursor-pointer border-1 w-full h-full border-blue-200 flex grow-1 p-0 px-[12px] pb-[12px] relative"
//       >
//         <div
//           className="border-[1px] w-full h-full border-grey-50 flex justify-center items-center bg-grey-background rounded-md bg-cover bg-center bg-no-repeat"
//           style={{ backgroundImage: `url(${entityContentData.blob_preview_url})` }}
//         >
//           {!entityContentData.blob_preview_url && (
//             <div
//               className="w-[80px] h-[80px] p-0 bg-cover bg-center bg-no-repeat"
//               style={{
//                 backgroundImage: `url(${getEntityDefaultIcon(
//                   isPackage,
//                   entityContentData
//                 )})`,
//               }}
//             />
//           )}
//           {entityContentData.blob_preview_url && entityContentData.media_type === "video" && (
//             <div className="w-full h-full flex items-center justify-center bg-[#00000033]">
//               <div
//                 className="w-[32px] h-[32px] p-0 bg-cover bg-center bg-no-repeat"
//                 style={{ backgroundImage: `url("/icons/play.png")` }}
//               />
//             </div>
//           )}
//         </div>
//         {(["shared_category", "shared_resource"].includes(entityData.media_type as string)) && (
//           <Import
//             color="#858992"
//             className="absolute right-6 bottom-4"
//           />
//         )}
//       </CardContent>
//     </Card>
//   );
};

export default memo(PlaylistContentEntity);
