import { FC, memo, useState } from "react";
import { t } from "i18next";
import { MoreVertical, Trash2 } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import AddCategoryDialog from "../../ui/dialog/AddCategoryDialog";
import { MediaCategoryDataType } from "@/types";
import AddUpdatePackageDialog from "@/components/ui/dialog/AddUpdatePackageDialog";
import dialogUiConfig from "@/components/ui/dialog/AddUpdatePackageDialog/dialogUiConfig";

type MediaCategoryActionsDropdownPropType = {
  onMenuDelete: () => void;
  canEdit: boolean;
  canDelete: boolean;
  apiPrefix: string;
  onUpdateCategory: (errMsg: string | null, successMsg?: string) => void,
  parentCategoryId?: string;
  selectedCategory?: MediaCategoryDataType;
};

const MediaCategoryActionsDropdown: FC<MediaCategoryActionsDropdownPropType> = ({
  onMenuDelete,
  canEdit,
  canDelete,
  apiPrefix,
  onUpdateCategory,
  parentCategoryId,
  selectedCategory,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <DropdownMenu
      modal={false}
      open={dropdownOpen}
      onOpenChange={setDropdownOpen}
    >
      <DropdownMenuTrigger
        className="px-[12px] items-center justify-center relative"
        onClick={(e) =>  {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <MoreVertical
          className="h-3.5 w-3.5 !m-0"
          color={"#11203D"}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {(!selectedCategory?.is_package || !!selectedCategory?.parent_id) && (
          <AddCategoryDialog
            disabled={!canEdit}
            onOpenClose={setDropdownOpen}
            apiPrefix={apiPrefix}
            parentCategoryId={parentCategoryId}
            selectedCategory={selectedCategory}
            onSubmit={onUpdateCategory}
          />
        )}
        {(selectedCategory?.is_package && !selectedCategory?.parent_id) && (
          <AddUpdatePackageDialog
            disabled={false}
            onOpenClose={setDropdownOpen}
            apiPrefix={apiPrefix}
            parentCategoryId={parentCategoryId}
            selectedPackage={selectedCategory}
            onSubmit={onUpdateCategory}
            CustomDialogTrigger={(
              <DropdownMenuItem
              disabled={false}
              className="w-full flex py-0 px-[8px]"
              onSelect={(e) => e.preventDefault()}
            >
              <div className="flex flex-row items-center w-full h-[32px]">
                <dialogUiConfig.updateContext.DialogTriggerIcon className="h-4 w-4 mr-[8px]" />
                {dialogUiConfig.updateContext.dialogTriggerLabel}
              </div>
            </DropdownMenuItem>
            )}
          />
        )}

        <DropdownMenuItem
          onClick={(e) => {
            e.stopPropagation();
            onMenuDelete();
          }}
          disabled={!canDelete}
        >
          <Trash2 className="h-3.5 w-3.5 mr-2" color="#11203D" />
          {t("mediaLibraryScreen.entity.delete")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default memo(MediaCategoryActionsDropdown);
