import { t } from "i18next";
import { Button } from "../ui/button";
import { ArrowUpDown } from "lucide-react";
import { ColumnDefTemplate, HeaderContext } from "@tanstack/react-table";
import { cn } from "@/lib/utils";

const renderColumnHeader: ColumnDefTemplate<HeaderContext<never, any>> = ({ column }) => {
  let headerLabel = "";

  switch (column.id) {
    case "name":
      headerLabel = t("accountScreen.users.table.name");
    break;
    case "email":
      headerLabel = t("accountScreen.users.table.email");
    break;
    case "last_active":
      headerLabel = t("accountScreen.users.table.lastActive");
    break;
    case "permission":
      headerLabel = t("accountScreen.users.table.permission");
    break;
    case "status":
      headerLabel = t("accountScreen.users.table.status");
    break;
    case "disabled":
      headerLabel = t("accountScreen.users.table.disabled");
    break;
    case "online":
      headerLabel = t("accountScreen.users.table.online");
    break;

    default: 
      headerLabel = "";
  }

  const getIsSorted = column.getIsSorted();

  return column.getCanSort() ? (
    <Button
      className={cn("focus-visible:ring-0 focus:ring-0 px-0", getIsSorted && "font-semibold")}
      variant="ghost"
      onClick={() => column.toggleSorting(getIsSorted === "asc")}
    >
      {headerLabel}
      {getIsSorted && (
        <ArrowUpDown className="ml-2 h-4 w-4" />
      )}
    </Button>
  ) : headerLabel;
};

export {
  renderColumnHeader,
};