import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  Row,
} from "@tanstack/react-table";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import React, { CSSProperties, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { GripHorizontal, Loader2, Trash } from "lucide-react";
import { cn } from "@/lib/utils";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";

export const RowDragHandleCell = memo(
  ({ rowId }: { rowId: UniqueIdentifier }) => {
    const { attributes, listeners } = useSortable({ id: rowId });

    return (
      <button {...attributes} {...listeners}>
        <GripHorizontal className="h-6 w-6" />
      </button>
    );
  }
);

const DraggableRow = memo(
  ({ row, onRowClick }: { row: Row<any>; onRowClick: any }) => {
    const { transform, transition, setNodeRef, isDragging } = useSortable({
      id: row.original.id, // row.id,
    });

    const style: CSSProperties = {
      transform: CSS.Transform.toString(transform),
      transition: transition,
      opacity: isDragging ? 0.8 : 1,
      zIndex: isDragging ? 1 : 0,
      position: "relative",
    };

    return (
      <TableRow
        className="cursor-pointer"
        style={style}
        ref={setNodeRef}
        onClick={() => {
          if (onRowClick) {
            onRowClick(row.original);
          }
          row.toggleSelected();
        }}
        data-state={row.getIsSelected() && "selected"}
      >
        {row.getVisibleCells().map((cell) => (
          <TableCell key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </TableRow>
    );
  }
);

interface PaginationState {
  pageIndex: number;
  pageSize: number;
}

interface DataTableProps<TData extends { id: UniqueIdentifier }, TValue> {
  dataFetching?: boolean;
  stickyHeader?: boolean;
  paginationEnabled?: boolean;
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  actionButtons?: React.ReactNode;
  searchField: string;
  onRowClick: (row: TData) => void;
  onRowSelectionDelete?: (rows: TData[]) => void;
  onRowDragEnd?: (activeId: UniqueIdentifier, overId: UniqueIdentifier) => void;
  pageSizes?: number[];
  initialPaginationState?: PaginationState;
  onPaginationStateUpdated?: (state: PaginationState) => void;
  enableRowSelection?: boolean;
  defaultSortingState?: SortingState
}

const defaultPageSizes = [20, 50, 100];

export function DataTable<TData extends { id: UniqueIdentifier }, TValue>({
  dataFetching = false,
  stickyHeader = false,
  paginationEnabled = true,
  columns,
  data,
  actionButtons,
  searchField,
  onRowClick,
  onRowSelectionDelete,
  onRowDragEnd,
  pageSizes = defaultPageSizes,
  initialPaginationState = {
    pageIndex: 0,
    pageSize: defaultPageSizes[0],
  },
  onPaginationStateUpdated,
  enableRowSelection = true,
  defaultSortingState = [],
}: DataTableProps<TData, TValue>) {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>(defaultSortingState);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState(initialPaginationState);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const dataIds = React.useMemo<UniqueIdentifier[]>(
    () => (data ? data.map((dataItem) => dataItem.id) : []),
    [data]
  );

  const table = useReactTable({
    enableRowSelection,
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex: true,
    getRowId: (row: any) => row.id,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getPaginationRowModel: paginationEnabled
      ? getPaginationRowModel()
      : undefined,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: (updater: any) => {
      setPagination((prevState) => updater(prevState));
    },
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
    initialState: {
      pagination: initialPaginationState,
    },
  });

  useEffect(() => {
    if (onPaginationStateUpdated) {
      onPaginationStateUpdated(pagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const renderTableBody = () => {
    if (dataFetching) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} className="h-48 text-center">
            <Loader2
              className={cn(
                "mx-auto my-auto h-16 w-16 text-primary/60 animate-spin"
              )}
            />
          </TableCell>
        </TableRow>
      );
    }

    if (table.getRowModel().rows?.length) {
      return table
        .getRowModel()
        .rows.map((row, ind) => (
          <DraggableRow
            key={`${row.original.id}`}
            row={row}
            onRowClick={onRowClick}
          />
        ));
    }

    return (
      <TableRow>
        <TableCell colSpan={columns.length} className="h-24 text-center">
          {t("common.noData")}
        </TableCell>
      </TableRow>
    );
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (onRowDragEnd && active && over && active.id !== over.id) {
      const oldIndex = dataIds.indexOf(active.id);
      const newIndex = dataIds.indexOf(over.id);

      return onRowDragEnd(oldIndex, newIndex);
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      sensors={sensors}
      onDragEnd={handleDragEnd}
    >
      {/* <div className="w-full"> */}
        <div className="flex items-center mb-4">
          <Input
            placeholder={t("common.filter")}
            value={
              (table.getColumn(searchField)?.getFilterValue() as string) ?? ""
            }
            onChange={(event) =>
              table.getColumn(searchField)?.setFilterValue(event.target.value)
            }
            className="max-w-sm ml-1 focus-visible:ring-0"
          />
          <div className="ml-auto flex items-center gap-3">
            {actionButtons}
            {onRowSelectionDelete &&
              table.getFilteredSelectedRowModel().rows.length > 0 && (
                <Button
                  className="gap-1 focus-visible:ring-0"
                  variant="destructive"
                  onClick={() => {
                    onRowSelectionDelete(
                      table
                        .getFilteredSelectedRowModel()
                        .rows.map((row) => row.original)
                    );
                  }}
                >
                  <Trash className="h-3.5 w-3.5" />
                  <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                    {t("common.delete")}
                  </span>
                </Button>
              )}
          </div>
        </div>
        {/* <div className="rounded-md border"> */}
          <Table stickyHeader={false}>
            <TableHeader className={stickyHeader ? "sticky top-0 bg-secondary z-10" : ""}  >
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              <SortableContext
                items={dataIds}
                strategy={verticalListSortingStrategy}
              >
                {renderTableBody()}
              </SortableContext>
            </TableBody>
          </Table>
        {/* </div> */}
        <div className="flex flex-1 fitems-around justify-between space-x-2 py-4 border-0 border-slate-200">
          {paginationEnabled && (
            <Select
              onValueChange={(value) => {
                table.setPagination(() => ({
                  pageIndex: 0,
                  pageSize: parseInt(value, 10),
                }));
              }}
              defaultValue={pagination.pageSize.toString()}
            >
              <SelectTrigger className="flex w-1/6 focus-visible:ring-0">
                <SelectValue className="focus-visible:ring-0 focus:ring-0" />
              </SelectTrigger>
              <SelectContent>
                {pageSizes.map((pageSize) => (
                  <SelectItem key={pageSize} value={pageSize.toString()}>
                    {pageSize}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          {/* <div className="flex-1 text-sm text-muted-foreground">
            {t("common.rowSelected", {
              selectedRow: table.getFilteredSelectedRowModel().rows.length,
              totalRow: table.getFilteredRowModel().rows.length,
            })}
          </div> */}
          {paginationEnabled && (
            <div className="space-x-2">
              <Button
                className="focus-visible:ring-0"
                variant="outline"
                size="sm"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {t("common.previous")}
              </Button>
              <Button
                className="focus-visible:ring-0"
                variant="outline"
                size="sm"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {t("common.next")}
              </Button>
            </div>
          )}
        </div>
      {/* </div> */}
    </DndContext>
  );
}
