import { t } from "i18next";
import { FC, memo } from "react";
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

type MediaLibraryBreadcrumbsPropsType = {
  category: any; // !TODO
  setCategoryId: (id: string|null) => void;
};

const MediaLibraryBreadcrumbs: FC<MediaLibraryBreadcrumbsPropsType> = ({
  category,
  setCategoryId,
}) => {
  return (
    <Breadcrumb className="border-0 border-slate-250 flex items-center flex-row justify-start overflow-hidden py-2 mb-2 h-12">
      <BreadcrumbList>
        {!category && (
          <BreadcrumbItem className="text-lg font-semibold text-[#11203D]">
            {t("mediaLibraryScreen.title")}
          </BreadcrumbItem>
        )}
        {category && (
          <>
            <BreadcrumbItem>
              <BreadcrumbLink
                onClick={() => setCategoryId(null)}
                className="cursor-pointer text-lg font-semibold text-[#11203D]"
              >
                {t("mediaLibraryScreen.title")}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            {category?.breadcrumbs.length > 0 && (
              <>
                {category?.breadcrumbs.length > 1 && (
                  <BreadcrumbItem>
                    <BreadcrumbEllipsis className="h-3.5" />
                  </BreadcrumbItem>
                )}
                <BreadcrumbItem className="cursor-pointer">
                  <BreadcrumbLink onClick={() => setCategoryId(category.parent_id)}>
                    {category?.breadcrumbs.slice(-1)}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
              </>
            )}
            {category && (
              <BreadcrumbItem className="cursor-pointer">
                <BreadcrumbPage>{category.name}</BreadcrumbPage>
              </BreadcrumbItem>
            )}
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export default memo(MediaLibraryBreadcrumbs);