import { useState, useEffect } from "react";
import Axios from "axios";

export interface SettingsSchema {
  nok_can_create_activities?: boolean;
  track_media_roles?: boolean;
  disable_start_activity_from_calendar?: boolean;
  enable_call_me_back_for_patients?: boolean;
  enable_chat_feature?: boolean;
  categories_icons_dataset?: "default" | "custom";
  loading?: boolean;
}

export function useSettings() {
  const [settings, setSettings] = useState<SettingsSchema>({
    nok_can_create_activities: false,
    track_media_roles: false,
    disable_start_activity_from_calendar: false,
    enable_call_me_back_for_patients: false,
    enable_chat_feature: false,
    categories_icons_dataset: "default",
    loading: false,
  });

  useEffect(() => {
    setSettings({ ...settings, loading: true });
    const fetchSettings = async () => {
      try {
        const res = await Axios.get("/api/account/settings");
        setSettings({ ...res.data, loading: false });
      } catch (error) {
        console.log(error);
        setSettings({ ...settings, loading: false });
      }
    };

    fetchSettings();
    // eslint-disable-next-line
  }, []);

  const updateSettings = async (newSettings: SettingsSchema) => {
    setSettings({ ...settings, loading: true });
    try {
      const res = await Axios.put("/api/account/settings", newSettings);
      setSettings({ ...res.data, loading: false });
    } catch (error) {
      console.log(error);
      setSettings({ ...settings, loading: false });
    }
  };

  return [settings, updateSettings];
}
