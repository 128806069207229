import { t } from "i18next";
import { Pencil, Youtube } from "lucide-react";

const dialogUiConfig = {
  nameFieldLabel: t("mediaLibraryScreen.addUpdatePlaylistDialog.nameFieldLabel"),
  nameFieldPlaceholder: t("mediaLibraryScreen.addUpdatePlaylistDialog.nameFieldPlaceholder"),
  tagsFieldLabel: t("mediaLibraryScreen.addUpdatePlaylistDialog.tagsFieldLabel"),
  tagsFieldPlaceholder: t("mediaLibraryScreen.addUpdatePlaylistDialog.tagsFieldPlaceholder"),
  nameFieldValidationMsg: t("mediaLibraryScreen.addUpdatePlaylistDialog.nameFieldValidationMsg"),
  createContext: {
    DialogTriggerIcon: Youtube,
    dialogTriggerLabelCommon: t("mediaLibraryScreen.addUpdatePlaylistDialog.createContext.dialogTriggerLabelCommon"),
    dialogTriggerLabelUser: t("mediaLibraryScreen.addUpdatePlaylistDialog.createContext.dialogTriggerLabelUser"),
    dialogHeaderTitle: t("mediaLibraryScreen.addUpdatePlaylistDialog.createContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("mediaLibraryScreen.addUpdatePlaylistDialog.createContext.dialogHeaderDescr"),
    submitBtnLabel: t("mediaLibraryScreen.addUpdatePlaylistDialog.createContext.submitBtnLabel"),
    submitSuccessMessage: t("mediaLibraryScreen.addUpdatePlaylistDialog.createContext.submitSuccessMessage"),
    submitErrorMessage: t("mediaLibraryScreen.addUpdatePlaylistDialog.createContext.submitErrorMessage"),
  },
  updateContext: {
    DialogTriggerIcon: Pencil,
    dialogTriggerLabelCommon: t("mediaLibraryScreen.addUpdatePlaylistDialog.updateContext.dialogTriggerLabelCommon"),
    dialogTriggerLabelUser: t("mediaLibraryScreen.addUpdatePlaylistDialog.updateContext.dialogTriggerLabelUser"),
    dialogHeaderTitle: t("mediaLibraryScreen.addUpdatePlaylistDialog.updateContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("mediaLibraryScreen.addUpdatePlaylistDialog.updateContext.dialogHeaderDescr"),
    submitBtnLabel: t("mediaLibraryScreen.addUpdatePlaylistDialog.updateContext.submitBtnLabel"),
    submitSuccessMessage: t("mediaLibraryScreen.addUpdatePlaylistDialog.updateContext.submitSuccessMessage"),
    submitErrorMessage: t("mediaLibraryScreen.addUpdatePlaylistDialog.updateContext.submitErrorMessage"),
  },
};

export default dialogUiConfig;