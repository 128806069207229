import { Fragment, memo, useMemo, useState } from "react"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { ChevronDown, ChevronUp } from "lucide-react";
import { t } from "i18next";
import { cn } from "@/lib/utils";
import { Input } from "../ui/input";
import TagsSelectionTree from "./TagsSelectionTree";
import { ScrollArea } from "../ui/scroll-area";
import { staticTagsConfig2, TagsConfigType } from "@/config";

type TagsMultiSelectDataType = {
  className?: string;
  placeholder?: string;
  defaultSelectedValues?: string[];
  disabledValues?: [];
  tagsData?: TagsConfigType;
  onChange: (selectedValues: string[]) => void;
  onBlur?: () => void;
  disabled: boolean;
  TriggerContentComponent?: React.ComponentType<{
    isOpen: boolean;
    selectedItems: { id: string; label: string; }[];
    noActiveValuesLabel?: string;
  }>;
  clearSelectionBtnLabel?: string
  onOpenClose?: (open: boolean) => void;
};

const TagsMultiSelect = ({
  className = "",
  placeholder = "",
  defaultSelectedValues = [],
  disabledValues = [],
  tagsData = staticTagsConfig2,
  disabled,
  onChange,
  TriggerContentComponent,
  clearSelectionBtnLabel = t("components.multiSelect.clearTagsBtn"),
  onOpenClose,
}: TagsMultiSelectDataType) => {
  const [open, setOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>(defaultSelectedValues);
  const [forceRemount, setForceRemount] = useState(Date.now());
  const [search, setSearch] = useState<string>("");

  const onOpenChange = (open: boolean) => {
    setOpen(open);

    if (onOpenClose) {
      onOpenClose(open);
    }
  };

  const selectedItems = useMemo(() => {
    const result: { id: string; label: string; } [] = [];

    if (defaultSelectedValues.length === 0) {
      return result
    };

    tagsData.forEach((tagsDataEntry) => {
      if (tagsDataEntry.type === "tag_category") {
        if (defaultSelectedValues.includes(tagsDataEntry.id)) {
          result.push(tagsDataEntry);
        }
        
        tagsDataEntry.items?.forEach((groupItem) => {
          if (groupItem.type === "tag_item"
            && defaultSelectedValues.includes(groupItem.id)
          ) {
            result.push(groupItem);
          } else if (groupItem.type === "tags_group") {
            groupItem?.items?.forEach((dataItem) => {
              if (defaultSelectedValues.includes(dataItem.id)) {
                result.push(dataItem);
              }
            })
          }
        })
      } else if (tagsDataEntry.type === "tags_group") {
        tagsDataEntry?.items?.forEach((dataItem) => {
          if (defaultSelectedValues.includes(dataItem.id)) {
            result.push(dataItem);
          }
        })
      } else {
        if (defaultSelectedValues.includes(tagsDataEntry.id)) {
          result.push(tagsDataEntry);
        }
      }
    });

    return result;
  }, [defaultSelectedValues, tagsData]);

  return (
    <Popover
      modal={true}
      open={open}
      onOpenChange={onOpenChange}
    >
      <PopoverTrigger
        className="flex focus-visible:ring-0"
        asChild
      >
        <Button
          disabled={disabled}
          className={cn("w-full justify-between", className)}
          role="combobox"
          variant="outline"
          aria-expanded={open}
          onClick={() => setOpen(!open)}
        >
          {TriggerContentComponent ? (
            <TriggerContentComponent
              noActiveValuesLabel={placeholder}
              selectedItems={selectedItems}
              isOpen={open}
            />
          ) : (
            <Fragment>
              <div className="w-full overflow-hidden mr-[16px] justify-start flex">
                {selectedItems.length === 0 ? (
                  <span className="text-muted-foreground">
                    {placeholder}
                  </span>
                ) : (
                  selectedItems.map((tagItem) => (
                    <span
                      className="bg-[#F1F4FA] mr-[8px] rounded-md px-[8px] py-[4px]"
                      key={tagItem.id as string}
                    >
                      {tagItem.label}
                    </span>
                  ))
                )}
              </div>
              {open ? (
                <ChevronUp
                  color="#11203D"
                  className="h-5 w-5 opacity-50"
                />
              ) : (
                <ChevronDown
                  color="#11203D"
                  className="h-5 w-5 opacity-50"
                />
              )}
            </Fragment>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="flex justify-between flex-col w-[377px] p-0 h-[350px] max-h-[350px] min-h-[350px] px-1"
        onKeyDown={(e) => {
          if (e.key === 'Escape' && open) {
            e.preventDefault();
            e.stopPropagation();
            setOpen(false);
          }
        }}
      >
        <Input
          onChange={(e) => setSearch(e.target.value)}
          className="my-1 focus-visible:ring-0"
          placeholder={placeholder}
        />
        <ScrollArea className="h-full">
          <TagsSelectionTree
            key={forceRemount}
            defaultSelectedIds={selectedIds}
            searchString={search}
            onSelectionChanged={setSelectedIds}
          />
        </ScrollArea>
        <div className="flex flex-1 justify-between p-2">
          <Button
            className="focus-visible:ring-0 flex flex-1 mr-1"
            onClick={() => {
              setSelectedIds([]);
              setForceRemount(Date.now());
            }}
            variant="outline"
          >
            {clearSelectionBtnLabel}
          </Button>
          <Button
            className="focus-visible:ring-0 flex flex-1 ml-1"
            onClick={() => {
              onChange(selectedIds);
              onOpenChange(false);
            }}
            variant="outline"
          >
            {t("common.save")}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default memo(TagsMultiSelect);
