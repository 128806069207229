import { FC, memo, useCallback, useState } from "react";
import Axios from "axios";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { t } from "i18next";
import { MediaContentDataType } from "@/types";
import dialogUiConfig from "./dialogUiConfig";
import DialogCommonFooter from "../DialogCommonFooter";
import GameFormSchema from "./GameFormSchema";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../select";
import { staticGamesConfig } from "@/config";

export type AddUpdateGameDialogPropsType = {
  apiPrefix: string;
  parentCategoryId?: string; 
  selectedContent?: MediaContentDataType,
  onOpenClose: (isOpen: boolean) => void;
  onSubmit: (errMsg: string | null, successMsg?: string) => void;
  disabled: boolean;
  // mediaLibraryUserId?: string;
};

const AddUpdateGameDialog: FC<AddUpdateGameDialogPropsType> = ({
  apiPrefix,
  parentCategoryId,
  selectedContent,
  onOpenClose,
  onSubmit,
  disabled,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitFetching, setSubmitFetching] = useState(false);
 
  const onOpenChange = useCallback((open: boolean) => {
    setDialogOpen(open);

    if (onOpenClose) {
      onOpenClose(open);
    }
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm<z.infer<typeof GameFormSchema>>({
    resolver: zodResolver(GameFormSchema),
    defaultValues: {
      gameName: selectedContent?.name ?? "",
      gameLinkValue: selectedContent?.metadata?.link ?? "",
    },
  });

  const uiConfig = !!selectedContent
    ? dialogUiConfig.updateContext
    : dialogUiConfig.createContext;

  const onSubmitForm = async (formData: z.infer<typeof GameFormSchema>) => {
    try {
      setSubmitFetching(true);

      const { status: playlistResponseStatus } = await Axios({
        method: selectedContent ? "PATCH" : "POST",
        url: !!selectedContent
          ? `${apiPrefix}/category/${parentCategoryId ?? "root"}/resource/${selectedContent.id}`
          : `${apiPrefix}/category/${parentCategoryId ?? "root"}/resource`,
        data: {
          description: selectedContent?.description ?? "",
          icon: selectedContent?.icon ?? "",
          preview_url: "",
          category_id: parentCategoryId,
          name: formData.gameName,
          media_type: "game",
          metadata: {
            "type": "neuronup",
            link: formData.gameLinkValue,
          },
        },
      });

      if (playlistResponseStatus !== 200 && playlistResponseStatus !== 201) {
        throw new Error("Error creating external url");
      }

      onOpenChange(false);
      onSubmit(null, uiConfig.successMsg);
    } catch (err) {
      console.error(err);
      onSubmit(uiConfig.errMsg);
    } finally {
      setSubmitFetching(false);
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={onOpenChange}
    >
      <DialogTrigger
        disabled={disabled}
        className="p-0 w-full"
      >
        <DropdownMenuItem
          disabled={disabled}
          className="w-full flex py-0 px-[8px]"
          onSelect={(e) => e.preventDefault()}
        >
          <div className="flex flex-row items-center w-full h-[32px]">
            <uiConfig.DialogTriggerIcon className="h-4 w-4 mr-[8px]" />
            <span>{uiConfig.dialogTriggerLabel}</span>
          </div>
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent className="flex flex-col w-[432px]">
        <DialogHeader>
          <DialogTitle>
            {uiConfig.dialogHeaderTitle}
          </DialogTitle>
          <DialogDescription>
            {uiConfig.dialogHeaderDescr}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            className="h-full"
            onSubmit={form.handleSubmit(onSubmitForm)}
          >
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="gameName"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel
                    className="mb-[6px]"
                    htmlFor="gameName"
                  >
                    {dialogUiConfig.gameNameFieldLabel}
                  </FormLabel>
                  <FormControl id="gameName">
                    <Input
                      disabled={submitFetching}
                      id="gameName"
                      className="focus-visible:ring-0"
                      placeholder={dialogUiConfig.gameNameFieldPlaceholder}
                      {...field}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="gameLinkValue"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel 
                    className="mb-[6px]"
                    htmlFor="gameLinkValue"
                  >
                    {dialogUiConfig.gameTypeFieldLabel}
                  </FormLabel>
                  <FormControl id="gameLinkValue">
                    <Select
                      defaultValue={field.value}
                      onValueChange={field.onChange}
                    >
                      <SelectTrigger
                        disabled={submitFetching}
                        className="w-full focus-visible:ring-0 focus:ring-0"
                      >
                        <SelectValue
                          className="items-center bg-slate-200 justify-center h-full border-2 border-slate-200"
                          placeholder={dialogUiConfig.gameTypeFieldPlaceholder}
                        />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {staticGamesConfig.map((game) => {
                            // border-2 border-slate-200 
                            return (
                              <SelectItem
                                className="items-center"
                                value={game.gameType}
                              >
                              {game.label}
                            </SelectItem>
                            );
                          })}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  {/*  */}
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
          </form>
        </Form>

        <DialogCommonFooter
          isFetching={submitFetching}
          onSubmitBtnClick={form.handleSubmit(onSubmitForm)}
          submitBtnLabel={uiConfig.submitBtnLabel}
          cancelBtnLabel={t("common.cancel")}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddUpdateGameDialog);
