import { t } from "i18next";

const formUiConfig = {
  featuresSettingsSectionLabel: t(
    "patientsScreen.settings.featuresSectionLabel"
  ),
  calendarSettingsSectionLabel: t(
    "patientsScreen.settings.calendarSectionLabel"
  ),
  customSettingsSectionLabel: t("patientsScreen.settings.customSectionLabel"),

  settingsUpdateErrMessage: t(
    "patientsScreen.settings.settingsUpdateErrMessage"
  ),
  settingsInitErrMessage: t("patientsScreen.settings.settingsInitErrMessage"),
  settingsUpdateSuccessMessage: t(
    "patientsScreen.settings.settingsUpdateSuccessMessage"
  ),

  featureViewMessagesLabel: t(
    "patientsScreen.settings.features.view_messages.label"
  ),
  featureViewMessageDescription: t(
    "patientsScreen.settings.features.view_messages.description"
  ),
  fatureViewMessagesErrMessage: t(
    "patientsScreen.settings.features.view_messages.errMessage"
  ),

  featureCallBackLabel: t("patientsScreen.settings.features.call_back.label"),
  featureCallbackDescription: t(
    "patientsScreen.settings.features.call_back.description"
  ),
  featureCallBackErrMessage: t(
    "patientsScreen.settings.features.call_back.errMessage"
  ),

  featureReplyMessagesLabel: t(
    "patientsScreen.settings.features.reply_messages.label"
  ),
  featureReplyMessagesDescription: t(
    "patientsScreen.settings.features.reply_messages.description"
  ),
  featureReplyMessagesErrMessage: t(
    "patientsScreen.settings.features.reply_messages.errMessage"
  ),

  featureAutoplayVideoLabel: t(
    "patientsScreen.settings.features.autoplay_video.label"
  ),
  featureAutoplayVideoDescription: t(
    "patientsScreen.settings.features.autoplay_video.description"
  ),
  featureAutoplayVideoErrMessage: t(
    "patientsScreen.settings.features.autoplay_video.errMessage"
  ),

  featureDislayStartTimeLabel: t(
    "patientsScreen.settings.features.display_start_time.label"
  ),
  featureDisplayStartTimeDescription: t(
    "patientsScreen.settings.features.display_start_time.description"
  ),
  featureDisplayStartTimeErrMessage: t(
    "patientsScreen.settings.features.display_start_time.errMessage"
  ),

  featureDislayFinishTimeLabel: t(
    "patientsScreen.settings.features.display_finish_time.label"
  ),
  featureDisplayFinishTimeDescription: t(
    "patientsScreen.settings.features.display_finish_time.description"
  ),
  featureDisplayFinishTimeErrMessage: t(
    "patientsScreen.settings.features.display_finish_time.errMessage"
  ),

  featureManageCalendarLabel: t(
    "patientsScreen.settings.features.manage_calendar.label"
  ),
  featureManageCalendarDescription: t(
    "patientsScreen.settings.features.manage_calendar.description"
  ),
  featureManageCalendarErrMessage: t(
    "patientsScreen.settings.features.manage_calendar.errMessage"
  ),

  featureMainScreenTypeLabel: t(
    "patientsScreen.settings.features.main_screen_type.label"
  ),
  featureMainScreenTypeDescription: t(
    "patientsScreen.settings.features.main_screen_type.description"
  ),
  featureMainScreenTypeErrMessage: t(
    "patientsScreen.settings.features.main_screen_type.errMessage"
  ),

  featureMainScreenTypeValueDefault: t(
    "patientsScreen.settings.features.main_screen_type.values.default"
  ),
  featureMainScreenTypeValueContrast: t(
    "patientsScreen.settings.features.main_screen_type.values.contrast"
  ),

  featureDisplayActivitiesModeLabel: t(
    "patientsScreen.settings.features.display_activities_mode.label"
  ),
  featureDisplayActivitiesModeDescription: t(
    "patientsScreen.settings.features.display_activities_mode.description"
  ),
  featureDisplayActivitiesModeErrMessage: t(
    "patientsScreen.settings.features.display_activities_mode.errMessage"
  ),

  featureDisplayActivitiesModeValueFull: t(
    "patientsScreen.settings.features.display_activities_mode.values.full_schedule"
  ),
  featureDisplayActivitiesModeValueLast3: t(
    "patientsScreen.settings.features.display_activities_mode.values.last_3_activities"
  ),

  featureNightTimePeriodLabel: t(
    "patientsScreen.settings.features.night_time_range.label"
  ),
  featureNightTimePeriodDescription: t(
    "patientsScreen.settings.features.night_time_range.description"
  ),
  featureNightTimePeriodErrMessage: t(
    "patientsScreen.settings.features.night_time_range.errMessage"
  ),
};

export default formUiConfig;
