import { useContext } from "react";
import Axios from "axios";
import _ from "lodash";

import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { LucideQrCode } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { useToast } from "@/components/ui/use-toast";
import { useError } from "app/utils";
import { CallDialog } from "@/components/ui/dialog/callDialog/callDialog";
import { ViewContext } from "components/view/view";

export function Profile(props) {
  const { t } = useTranslation();
  const context = useContext(ViewContext);
  const { toast } = useToast();
  const [handleError] = useError();

  const showLoginQR = async () => {
    try {
      const res = await Axios.get(`/api/user/${props.patientId}/magic`);
      const token = res.data?.token;
      if (!token) {
        toast({
          title: t("patientsScreen.profile.qrError"),
          variant: "destructive",
        });
        return;
      }

      context.qrDialog.show(token);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <div className="px-6 py-2 border-b">
      <div className="flex justify-between">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="flex gap-2">
              <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight">
                {props.name}
              </h3>
            </div>
            <Badge variant={props.online ? "secondary" : "destructive"}>
              {props.online
                ? t("accountScreen.users.table.onlineStatus")
                : t("accountScreen.users.table.offlineStatus")}
            </Badge>
            <p className="text-sm text-muted-foreground ml-6">
              {t("patientsScreen.profile.batteryLevel")}
              {": "}
              {Math.round(props.batteryLevel * 100)}%
            </p>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <Button
            variant="ghost"
            size="icon"
            className="rounded-full bg-[#F1F4FA] hover:bg-[#F1F4FA]/40"
            onClick={showLoginQR}
          >
            <LucideQrCode className="h-4 w-4" />
          </Button>
          <CallDialog patientId={props.patientId} name={props.name} />
        </div>
      </div>
    </div>
  );
}
