
import { cn } from "@/lib/utils";
import { memo, useMemo, useState } from "react";
import { Label } from "../ui/label";
import { Checkbox } from "../ui/checkbox";
import { t } from "i18next";
import { staticTagsConfig2, TagsConfigType } from "@/config";

const TagsDataItem = ({
  data,
  className,
  onCheckClick,
  selectedIds,
}: { data: any, className?: string; onCheckClick: any, selectedIds: any[]; }) => {
  return (
    <div className={cn("border-0 flex items-center", className)}>
      <Checkbox
        onClick={onCheckClick}
        checked={selectedIds.includes(data.id)}
        disabled={false}
        className="focus-visible:ring-0 mr-[8px] border-[#E0E0E0] data-[state=checked]:bg-[#1949A3] data-[state=checked]:border-[#E0E0E0]"
        aria-label="select item"
        id={data.id}
      />
      <Label
        htmlFor={data.id}
        className="truncate w-full peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer font-normal text-sm text-[#11203D] border-0"
      >
        {data.label}
      </Label>
    </div>
  );
};

const TagsDataGroup = ({
  data,
  onCheckClick,
  selectedIds,
}: { data: any, className?: string; onCheckClick: any; selectedIds: any[]; }) => {
  return (
    <div className="overflow-hidden mb-1">
      <span className={`
        overflow-hidden
        truncate
        text-muted-foreground
        text-xs
        font-medium
        font-semibold
        text-sm
        text-[#11203D]
      `}>
        {data.label}
      </span>
      {/* group content */}
      <div className="py-1 ml-2">
        {data.items.map((groupItem: any) => {
          return (
            <TagsDataItem
              data={groupItem}
              onCheckClick={onCheckClick}
              selectedIds={selectedIds}
            />
          );
        })}
      </div>
    </div>
  );
};  

const TagsDataSection = ({
  data,
  onCheckClick,
  selectedIds,
}: { data: any; onCheckClick: any; selectedIds: any[] }) => {
  return (
    <div className="mb-2 overflow-hidden">
      <div className="flex items-center">
        <Checkbox
          onClick={onCheckClick}
          checked={selectedIds.includes(data.id)}
          disabled={false}
          className="focus-visible:ring-0 mr-[8px] border-[#E0E0E0] data-[state=checked]:bg-[#1949A3] data-[state=checked]:border-[#E0E0E0]"
          aria-label="select item"
          id={data.id}
        />
        <Label
          htmlFor={data.id}
          className="truncate cursor-pointer font-normal text-md text-[#11203D]"
        >
          {data.label}
        </Label>
      </div>
      <div className="ml-2">
        {data.items.map((sectionData: any) => {
          if (sectionData.type === "tags_group") {
            return (<TagsDataGroup data={sectionData} onCheckClick={onCheckClick} selectedIds={selectedIds} />)
          }
          
          return (
            <TagsDataItem
              className=""
              data={sectionData}
              onCheckClick={onCheckClick}
              selectedIds={selectedIds}
            />
          );
        })}
      </div>
    </div>
  );
};

const searchTreeItems = (
  item: any,
  checkItemCb: (item: any) => boolean,
) => {
  if (item.type === "tag_item"
    && checkItemCb(item)
  ) {
    return item;
  }

  if (item.type === "tags_group") {
    const itemsFiltered = item.items
      .map((child: any) => searchTreeItems(child, checkItemCb))
      .filter((it: any) => !!it);

    if (itemsFiltered.length > 0) {
      return {
        ...item,
        items: itemsFiltered,
      };
    }
    return null;
  }

  if (item.type === "tag_category") {
    console.log("tag_category >>>", item); // !DEBUG
    const itemsFiltered = item.items
      .map((child: any) => searchTreeItems(child, checkItemCb))
      .filter((it: any) => !!it);

    if (itemsFiltered.length > 0
      || checkItemCb(item)
    ) {
      return {
        ...item,
        items: itemsFiltered,
      };
    }
    return null;
  }

  return null;
};

type TreeItemProps = {
  data?: TagsConfigType;
  searchString?: string;
  onSelectionChanged?: (selectedIds: any[]) => void;
  defaultSelectedIds?: string[];
};

const TagsSelectionTree = ({
  data = staticTagsConfig2,
  searchString = "",
  onSelectionChanged,
  defaultSelectedIds = [], 
}: TreeItemProps) => {
  const [selectedIds, setSelectedIds] = useState<string[]>(defaultSelectedIds);

  const onCheckClick = (e: any) => {
    const wasChecked = e.target.dataset.state === "checked";
    let newSelectedIds = selectedIds;

    if (wasChecked) {
      newSelectedIds = selectedIds.filter((id) => id !== e.target.id);
    } else {
      const rootItemAffected = data.find((section: any) => {
        return searchTreeItems(section, (item) => item.id === e.target.id)
      });

      newSelectedIds = rootItemAffected?.type === "tag_category"
        ? selectedIds.concat([...new Set([rootItemAffected.id, e.target.id])])
        : selectedIds.concat(e.target.id)
    }

    setSelectedIds(newSelectedIds);
    onSelectionChanged?.(newSelectedIds)
  }

  const dataToDisplay = useMemo(() => {
    const nameContainSearch = (item: any) => item.label
      .toLowerCase()
      .includes(searchString.toLowerCase());
 
    const result = searchString.length ? data.reduce((acc: any[], dataEntry: any) => {
      const dataBySearch = searchTreeItems(dataEntry, nameContainSearch);
      return dataBySearch
        ? acc.concat(dataBySearch)
        : acc;
    }, []) : data;


    return result;
  }, [data, searchString]);

  if (!dataToDisplay.length) {
    return (
      <div className="flex flex-1 items-center justify-center p-6 h-full">
        <span className="text-center">
          {t("components.multiSelect.notFound")}
        </span>
      </div>
    );
  }

  return (
    <div className="p-2 h-full">
      {dataToDisplay.map((tagsDataEntry: any) => {
        let ComponentToRender = TagsDataItem;

        if (tagsDataEntry.type === "tag_category") {
          ComponentToRender = TagsDataSection;
        } else if (tagsDataEntry.type === "tags_group") {
          ComponentToRender = TagsDataGroup;
        }

        return (
          <ComponentToRender
            onCheckClick={onCheckClick}
            key={tagsDataEntry.id}
            data={tagsDataEntry}
            selectedIds={selectedIds}
          />
        );
      })}
    </div>
  );
};

export default memo(TagsSelectionTree);