import { MEDIA_FILE_STUB_TYPE } from "@/config";
import { MediaContentDataType } from "@/types";

const getFilesMediaType = (files: File[]) => {
  let lastType = null;

  if (files.length === 0) {
    return null;
  }

  for (let i = 0, len = files.length; i < len; i += 1) {
    const { type } = files[i];

    if (lastType !== null && type !== lastType) {
      return null;
    }

    lastType = type;
  }

  if (lastType === "video/mp4" ) {
    return "video";
  }

  if (lastType === "application/pdf") {
    return "document";
  }

  return null;
};

const getMockFileFromContent = (content?: MediaContentDataType) => {
  if (!content) {
    return [];
  }
  
  const fileName = content?.metadata?.fileName ?? (content?.blob_name?.split("/")?.at(-1) ?? "unknown");
  const fileType = content?.metadata?.fileType ?? MEDIA_FILE_STUB_TYPE;
  const fileSize = content?.metadata?.fileSize ?? "0";
  const blob = new Blob(['a'.repeat(parseInt(fileSize, 10))]);

  const result = [new File([blob], fileName, {
    type: fileType,
    lastModified: 0, // used as flag 
  })];

  return result;
}

export {
  getFilesMediaType,
  getMockFileFromContent,
};