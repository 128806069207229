import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { useSettings } from "app/hooks/settings";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";

const SettingsFormSchema = z.object({
  nok_can_create_activities: z
    .boolean()
    .default(false)
    .optional(),
  enable_call_me_back_for_patients: z
    .boolean()
    .default(false)
    .optional(),
  track_media_roles: z
    .boolean()
    .default(false)
    .optional(),
  disable_start_activity_from_calendar: z
    .boolean()
    .default(false)
    .optional(),
  enable_chat_feature: z
    .boolean()
    .default(false)
    .optional(),
  categories_icons_dataset: z
    .enum(["default", "custom"])
    .default("default")
});

// defaultValues: activeSettings ?? patientSettingsDefault,
export function Settings() {
  const [settings, updateSettings] = useSettings();
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof SettingsFormSchema>>({
    resolver: zodResolver(SettingsFormSchema),
  });

  useEffect(() => {
    form.reset(settings as any);
  }, [settings, form]);

  async function onSubmit(data: z.infer<typeof SettingsFormSchema>) {
    // @ts-ignore
    await updateSettings(data);
  }

  return (
    <Card>
      <CardHeader className="px-7">
        <CardTitle>{t("accountScreen.settings.header")}</CardTitle>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full space-y-6"
          >
            <div>
              <div className="space-y-4">
                {[
                  {
                    fieldName: "nok_can_create_activities" as any,
                    title: t(
                      "accountScreen.settings.nok_can_create_activities.title"
                    ),
                    description: t(
                      "accountScreen.settings.nok_can_create_activities.description"
                    ),
                  },
                  {
                    fieldName: "enable_call_me_back_for_patients" as any,
                    title: t(
                      "accountScreen.settings.enable_call_me_back_for_patients.title"
                    ),
                    description: t(
                      "accountScreen.settings.enable_call_me_back_for_patients.description"
                    ),
                  },
                  {
                    fieldName: "track_media_roles" as any,
                    title: t("accountScreen.settings.track_media_roles.title"),
                    description: t(
                      "accountScreen.settings.track_media_roles.description"
                    ),
                  },
                  {
                    fieldName: "disable_start_activity_from_calendar" as any,
                    title: t(
                      "accountScreen.settings.disable_start_activity_from_calendar.title"
                    ),
                    description: t(
                      "accountScreen.settings.disable_start_activity_from_calendar.description"
                    ),
                  },
                  {
                    fieldName: "enable_chat_feature" as any,
                    title: t(
                      "accountScreen.settings.enable_chat_feature.title"
                    ),
                    description: t(
                      "accountScreen.settings.enable_chat_feature.description"
                    ),
                  },
                ].map((feature) => (
                  <FormField
                    control={form.control}
                    name={feature.fieldName}
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                        <div className="space-y-0.5">
                          <FormLabel className="text-base">
                            {feature.title}
                          </FormLabel>
                          <FormDescription>
                            {feature.description}
                          </FormDescription>
                        </div>
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                ))}

                {/* ========== */}
                <FormField
                  control={form.control}
                  name="categories_icons_dataset"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-start justify-between rounded-lg border p-4">
                      <div className="space-y-0.5">
                        <FormLabel className="text-base">
                          {t("accountScreen.settings.categories_icons_dataset.title")}
                        </FormLabel>
                        <FormDescription>
                          {t("accountScreen.settings.categories_icons_dataset.description")}
                        </FormDescription>
                      </div>
                      <FormControl>
                        <RadioGroup
                          id="categories_icons_dataset_group"
                          className="border-0 gap-1 flex flex-col"
                          value={field.value}
                          onValueChange={(value) => {
                            field.onChange(value);
                          }}
                        >
                          <div className="flex items-center space-x-2 border-0 my-0">
                            <RadioGroupItem
                              className="text-[#1949a3]"
                              value="default"
                              id="id_default"
                            />
                            <Label
                              className="text-sm font-normal text-[#11203D]"
                              htmlFor="id_default"
                            >
                              {t("accountScreen.settings.categories_icons_dataset.values.default")}
                            </Label>
                            </div>
                            <div className="flex items-center space-x-2 border-0 my-0">
                              <RadioGroupItem
                                className="text-[#1949a3]"
                                value="custom"
                                id="id_custom"
                              />
                              <Label
                                className="text-sm font-normal text-[#11203D]"
                                htmlFor="id_custom"
                              >
                                {t("accountScreen.settings.categories_icons_dataset.values.custom")}
                              </Label>
                            </div>
                        </RadioGroup>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <Button className="" type="submit">
              {t("common.save")}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
