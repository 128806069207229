// API layer

import Axios from "axios";
import moment from "moment-timezone";
import {
  MediaCategoryDataType,
  MediaContentDataType,
  MediaType,
  PatientSettingsType,
} from "./types";
import { PatientSettingsFormSchema } from "./config";

// !TODO: name from common library
const apiImportMediaLibraryContent = async (
  parentCategoryId: string | undefined,
  mediaResourceItems: MediaContentDataType[],
  mediaCategoryItems: MediaCategoryDataType[],
  apiPrefix: string,
  copyLinkedPlaylist: boolean = false,
) => {
  const usedCategoryId = parentCategoryId ?? "root";
 
  for (const mediaResourceItem of mediaResourceItems) {
    const isPlaylist = mediaResourceItem.media_type === "playlist";

    const result = isPlaylist && copyLinkedPlaylist
      ? await Axios.post(`${apiPrefix}/category/${usedCategoryId}/copy_playlist`, {
          resource_id: mediaResourceItem.id,
          category_id: usedCategoryId,
        })
      : await Axios.post(`${apiPrefix}/category/${usedCategoryId}/resource`, {
          media_type: "shared_resource",
          linked_resource_id: mediaResourceItem.id,
          copy_linked_playlist: copyLinkedPlaylist,
          category_id: usedCategoryId,
        });

    if (result.status !== 201) {
      throw new Error("Error importing resources");
    }
  }

  for (const mediaCategoryItem of mediaCategoryItems) {
    const result = await Axios.post(`${apiPrefix}/category/${usedCategoryId}/resource`, {
      category_id: usedCategoryId,
      media_type: "shared_category",
      linked_resource_id: mediaCategoryItem.id,
      copy_linked_playlist: copyLinkedPlaylist,
    });

    if (result.status !== 201) {
      throw new Error("Error importing categories");
    }
  }
};

const apiAddPlaylistItems = async (
  mediaResourceItems: MediaContentDataType[],
  firstIndexToAdd: number,
  apiPrefix: string,
  playlistId: string,
  categoryId?: string | null,
) => {
  let sort_order = firstIndexToAdd;
      
  for (const { id: resource_id} of mediaResourceItems) {
    await Axios.post(
      `${apiPrefix}/category/${categoryId ?? "root"}/resource/${playlistId}/playlist`,
      { resource_id, sort_order }
    );

    sort_order += 1;
  }
};


const apiGetMediaLibraryCategories = async (apiPrefix?: string) => {
  const categoriesData: MediaCategoryDataType[] = (
    await Axios.get(`${apiPrefix}/category/common`)
  )?.data;

  if (!categoriesData) {
    throw new Error("error getting resources categories data");
  }

  return categoriesData;
};

const apiGetMediaLibraryResources = async (
  apiPrefix: string,
  filters?: Partial<MediaContentDataType & { media_type?: MediaType[]}>,
  includeLinks?: boolean,
) => {
  const resourcesData: MediaContentDataType = (
    await Axios.get(`${apiPrefix}/category/resource/common`, { params: { filters, includeLinks } })
  )?.data;

  if (!resourcesData) {
    throw new Error("error getting resources categories data");
  }

  return resourcesData;
};

const apiGetPatientSettings = async (patientId: string) => {
  const responseData = (await Axios.get(`/api/patient/${patientId}/settings`))?.data;
  const responseParsed = PatientSettingsFormSchema.safeParse(responseData);

  let result = responseParsed.success
    ? responseParsed.data as PatientSettingsType
    : null;

  return result;
};

const apiUpdatePatientSettings = async(
  patientId: string,
  settings: PatientSettingsType,
) => {
  const result = (await Axios.put(`/api/patient/${patientId}/settings`, {
    settings,
  }))?.data;

  return result;
};

type CalendarEventDataType = any; // !TODO
const defaultTimezone = "Europe/Oslo";

const apiGetPatientCalendarEvents = async (
  patientId: string,
  accountId: string,
  startDate: string, // !DEAULT VALUE?
  endDate: string, // !DEAULT VALUE?
): Promise<CalendarEventDataType[]> => {
  const time_zone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? defaultTimezone;
  const utc_offset = moment.utc().tz(time_zone).utcOffset() ?? 0;
  
  const result = (await Axios.get(
    "/api/calendar",
    {
      params: {
        start_date: startDate,
        end_date: endDate,
        patient_id: patientId,
        account_id: accountId,
        time_zone,
        utc_offset,
      }
    }
  )).data ?? [];

  return result;
};

// !TODO: argument types
const apiCreatePatientCalendarEvent = async (eventData: any) => {
  const time_zone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? defaultTimezone;
  const utc_offset = moment.utc().tz(time_zone).utcOffset() ?? 0;

  const { status } = await Axios({
    method: "POST",
    url: "/api/calendar",
    data: {
      ...eventData,
      time_zone,
      utc_offset,
    }
  });

  if (status !== 201 && status !== 200) {
    return false;
  }

  return true;
};

// !TODO: argument types
const apiUpdatePatientCalendarEvent = async (eventId: string, eventData: any) => {
  const time_zone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? defaultTimezone;
  const utc_offset = moment.utc().tz(time_zone).utcOffset() ?? 0;

  const { status } = await Axios({
    method: "PATCH",
    url: "/api/calendar/" + eventId,
    data: {
      ...eventData,
      time_zone,
      utc_offset,
    },
  });

  if (status !== 201 && status !== 200) {
    return false;
  }

  return true;
};

const apiRemoveCalendarEvent = async(eventId: string) => {
  await Axios.delete(`/api/calendar/${eventId}`);
  return true;
};

export {
  apiGetPatientCalendarEvents,
  apiCreatePatientCalendarEvent,
  apiUpdatePatientCalendarEvent,
  apiRemoveCalendarEvent,
  apiImportMediaLibraryContent,
  apiAddPlaylistItems,
  apiGetMediaLibraryCategories,
  apiGetMediaLibraryResources,
  apiGetPatientSettings,
  apiUpdatePatientSettings,
};
